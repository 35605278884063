<template>
  <vbt-table
    :headers="headers"
    :show-paginator="paged"
    :page-size="pageSize"
    :items="items"
  >
    <template #amount="{ item }">
      {{ formatNumber(item.amount) }}
      / {{ formatNumber(item.taxAmount) }}
      / {{ formatNumber(item.amountWithTax) }}
    </template>

    <template #dateCreated="{ item: { dateCreated } }">
      {{ formatDisplayDate(dateCreated) }}
    </template>

    <template #date="{ item }">
      {{ formatDisplayDate(item.date, { dateFormat: 'yyyy-MM-dd' }) }}
    </template>

    <template #download="{ item }">
      <v-btn-toggle
        :value-comparator="() => false"
        mandatory
      >
        <v-btn
          v-for="(format, i) in fileFormats"
          :key="i"
          x-small
          text
          @click="downloadInvoiceFile(item.id, format)"
        >
          {{ format }}
        </v-btn>
      </v-btn-toggle>
    </template>
  </vbt-table>
</template>

<script>
import { formatDisplayDate, formatNumber, wrapToLoadingFn } from '@helpers';

import { InvoicesApiService } from '../_services';

const headers = Object.freeze([
  { text: 'Account', value: 'accountName' },
  { text: 'Number', value: 'number' },
  { text: 'MSBC Invoice Number', value: 'msbсInvoiceNumber' },
  { text: 'Amount/Tax/With Tax', value: 'amount' },
  { text: 'Created Date', value: 'dateCreated' },
  { text: 'Date', value: 'date' },
  { text: 'Download', value: 'download' },
]);

export default {
  name: 'IrInvoicesList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    paged: {
      type: Boolean,
      default: false,
    },

    pageSize: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      fileFormats: ['pdf', 'csv', 'xlsx'],

      headers,
    };
  },

  methods: {
    downloadInvoiceFile(invoiceId, format) {
      this.wrapToLoadingFn({
        req: InvoicesApiService.getInvoiceFileURL.bind({}, { invoiceId, format }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: url => window.open(url, '_blank'),
      });
    },

    formatDisplayDate,
    formatNumber,
    wrapToLoadingFn,
  },
};
</script>
